<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6" sm="8" class="p-0 m-0">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h1>회원 정보</h1>

              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="이름" v-model="user.username" />
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>@</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="이메일" v-model="user.email" />
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="API Key" v-model="user.apiKey" />
                <b-button @click.prevent="createUuid()" variant="primary" style="color:white">
                  생성
                </b-button>
              </b-input-group>

              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-select v-model="user.level" :options="levelOptions" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" class="form-control" placeholder="비밀번호" autocomplete="new-password"
                  v-model="user.password" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-tag"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="추천코드" v-model="user.recommendCode" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-speech"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <select type="text" class="form-control" v-model="user.providerId">
                  <option v-for="provider in providers" :value="provider.id" :key="provider.id">
                    {{ provider.title }}
                  </option>
                </select>
              </b-input-group>
              <b-input-group class="mb-3" v-show="user.providerId == 10">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-phone"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="발신번호" v-model="user.originator" />
              </b-input-group>
              <b-input-group class="mb-3" v-if="user.level >= 6">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-tag"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="관리코드" v-model="user.manageCodes" />
              </b-input-group>
              <b-input-group class="mb-3" v-if="this.$store.state.user.level == 9">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-calculator"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="보유금액" v-model="userMoney" readonly />
                <button v-show="this.$store.state.user.level == 9" class="btn btn-success ml-1"
                  @click.prevent="showPlusAmountModal">
                  충전
                </button>
                <button v-show="this.$store.state.user.level == 9" class="btn btn-warning ml-1"
                  @click.prevent="showMinusAmountModal">
                  차감
                </button>
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-calendar"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="가입일" v-model="userCreatedAt" readonly />
              </b-input-group>
              <!-- <b-input-group class="mb-3">
                <b-form-checkbox type="checkbox" class="form-control" v-model="useLong">긴문자 사용</b-form-checkbox>
              </b-input-group> -->
              <b-input-group class="mb-3">
                <b-form-checkbox type="checkbox" class="form-control" v-model="useRefund">실패시 자동 환불 처리</b-form-checkbox>
              </b-input-group>
              <!-- <b-input-group class="mb-3" style="display:flex;flex-flow:row">
                <b-form-checkbox type="checkbox" class="form-control" v-model="canDownload"
                  >발송결과 다운로드</b-form-checkbox
                >
                <b-button @click.prevent="requestAllAttachments" variant="light" class="ml-1"
                  >총 발송내역 다운로드</b-button
                >
              </b-input-group> -->
              <b-input-group class="mb-3">
                <b-button @click.prevent="pushPayments" variant="dark" class="ml-2">충전내역 조회</b-button>
                <b-button @click.prevent="pushRequests" variant="dark" class="ml-2">발송내역 조회</b-button>
                <b-button @click.prevent="pushTestRequests" variant="dark" class="ml-2">3사 조회</b-button>
                <b-button @click.prevent="openNoteModal()" variant="warning" class="ml-2" style="color:white">
                  쪽지
                </b-button>
                <!-- <b-button @click.prevent="logoutUser" variant="danger" class="ml-2">강제 로그아웃</b-button> -->
              </b-input-group>
              <div class="btn-wrap">
                <b-button @click.prevent="$router.go(-1)" variant="secondary">이전</b-button>
                <b-button v-show="this.$store.state.user.level == 9" @click.prevent="updateUser"
                  variant="primary">수정</b-button>
                <b-button v-show="this.$store.state.user.level == 9" @click.prevent="deleteUser"
                  variant="danger">삭제</b-button>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="noteModal" :title="user.username + '님에게 보내기'" centered hide-footer>
      <b-form-input type="text" class="form-control" placeholder="내용" v-model="noteBody" />

      <b-btn class="mt-3" ref="sendBtn" variant="primary" block @click="createNote">보내기</b-btn>
    </b-modal>
    <b-modal ref="plusAmountModal" :title="'금액 충전하기'" centered hide-footer>
      <b-form-input type="text" class="form-control" placeholder="금액을 입력하세요" v-model="changeAmount" />

      <b-btn class="mt-3" variant="success" block @click="plusAmount">충전하기</b-btn>
    </b-modal>
    <b-modal ref="minusAmountModal" :title="'금액 차감하기'" centered hide-footer>
      <b-form-input type="text" class="form-control" placeholder="금액을 입력하세요" v-model="changeAmount" />

      <b-btn class="mt-3" variant="warning" block @click="minusAmount">차감하기</b-btn>
    </b-modal>
  </div>
</template>

<script>
import SmsService from '@/services/SmsService'
import UserService from '@/services/UserService'
import NoteService from '@/services/NoteService'
import SmsProviderService from '@/services/SmsProviderService'

export default {
  name: 'User',
  mounted() {
    document.body.classList.remove('bg-white')
    this.getUser()
    this.getSmsProviders()
  },
  data() {
    return {
      user: {},
      userMoney: null,
      userCreatedAt: null,
      levelOptions: [
        {
          value: 1,
          text: '회원',
        },
        {
          value: 6,
          text: '총판',
        },
        {
          value: 8,
          text: '부관리자',
        },
        {
          value: 9,
          text: '관리자',
        },
      ],
      useRefund: false,
      useLong: false,
      canDownload: false,
      noteBody: '',
      changeAmount: null,
      providers: []
    }
  },
  watch: {
    canDownload() { },
    useRefund() { },
  },
  methods: {
    createUuid() {
      this.user.apiKey = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
        .replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
        .replace(/-/g, '')
      return false
    },
    timeFormat(dateString) {
      return (new Date(dateString)).toLocaleString()
    },
    numberFormat(value) {
      if (!value) return '0'
      var regexp = /\B(?=(\d{3})+(?!\d))/g
      return value.toString().replace(regexp, ',')
    },
    async getUser() {
      const response = await UserService.getUser(this.$route.params.id)
      if (response.status == 200) {
        const user = response.data.user
        this.user = user
        this.userMoney = this.numberFormat(user.money)
        this.userCreatedAt = this.timeFormat(user.createdAt)
        this.canDownload = user.canDownload == 1
        this.useRefund = user.useRefund == 1
        this.useLong = user.useLong == 1
      }
    },
    async updateUser() {
      const response = await UserService.updateUser(this.user.id, {
        email: this.user.email,
        password: this.user.password,
        recommendCode: this.user.recommendCode,
        providerId: this.user.providerId,
        originator: this.user.originator,
        manageCodes: this.user.manageCodes,
        level: this.user.level,
        canDownload: this.canDownload ? 1 : 0,
        useRefund: this.useRefund ? 1 : 0,
        useLong: this.useLong ? 1 : 0,
        username: this.user.username,
        apiKey: this.user.apiKey,
      })

      if (response.status == 200) {
        alert('수정 완료')
      }
    },
    async deleteUser() {
      if (!confirm('삭제하시겠습니까?')) return
      const response = await UserService.deleteUser(this.user.id)
      if (response.status == 200) {
        alert('삭제 완료')
        this.$router.go(-1)
      }
    },
    async getSmsProviders() {
      const response = await SmsProviderService.list()
      if (response.status == 200) {
        this.providers = response.data.list
      }
    },
    async showPlusAmountModal() {
      this.changeAmount = null
      this.$refs['plusAmountModal'].show()
    },
    async showMinusAmountModal() {
      this.changeAmount = null
      this.$refs['minusAmountModal'].show()
    },
    async plusAmount() {
      try {
        let changeAmount = this.changeAmount
        if (!changeAmount) {
          return
        }

        const confirmString = `[${changeAmount}]을 [충전]하시겠습니까?`
        if (!confirm(confirmString)) {
          return
        }

        changeAmount = changeAmount.replace(/,/g, '')
        changeAmount = parseFloat(changeAmount)
        if (isNaN(changeAmount) || changeAmount < 0) {
          alert('유효한 값을 입력해주세요')
          return
        }

        const response = await UserService.changeUserAmount(this.user.id, changeAmount)
        if (response.status !== 200) {
          return
        }

        this.getUser()
      }
      catch (e) {
        // do nothing
      } finally {
        this.$refs['plusAmountModal'].hide()
      }
    },
    async minusAmount() {
      try {
        let changeAmount = this.changeAmount
        if (!changeAmount) {
          return
        }
        const confirmString = `[${changeAmount}]을 [차감]하시겠습니까?`
        if (!confirm(confirmString)) {
          return
        }

        changeAmount = changeAmount.replace(/,/g, '')
        changeAmount = parseFloat(changeAmount)
        if (isNaN(changeAmount) || changeAmount < 0) {
          alert('유효한 값을 입력해주세요')
          return
        }

        const response = await UserService.changeUserAmount(this.user.id, -changeAmount)
        if (response.status !== 200) {
          return
        }

        this.getUser()
      }
      catch (e) {
        // do nothing
      } finally {
        this.$refs['minusAmountModal'].hide()
      }
    },
    async requestAllAttachments() {
      SmsService.getAllAttachment(this.$route.params.id).then(response => {
        const url = window.URL.createObjectURL(new Blob(['\ufeff', response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.user.email + '.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    pushPayments() {
      this.$router.push({
        path: '/payment/history',
        query: {
          userId: this.user.id,
        },
      })
    },
    pushRequests() {
      this.$router.push({
        path: '/SmsRequests',
        query: {
          userId: this.user.id,
        },
      })
    },
    pushTestRequests() {
      this.$router.push({
        path: '/SmsSendTest',
        query: {
          userId: this.user.id,
        },
      })
    },
    async createNote() {
      try {
        await NoteService.create({ userId: this.user.id, body: this.noteBody })
        this.noteBody = ''
        this.$refs['noteModal'].hide()
        this.getStatus()
      } catch (e) {
        // Do nothing
      }
    },
    openNoteModal() {
      this.$refs['noteModal'].show()
    },
    async logoutUser() {
      try {
        await UserService.logoutUser(this.user.id)
      } catch (e) {
        // Do nothing
      }
    }
  },
}
</script>
<style>
.modal-dialog-centered {
  margin: 0 auto !important;
  height: 100% !important;
  min-height: 100% !important;
}

.modal-dialog-centered .modal-content {
  margin-top: -200px !important;
}

.custom-control-label {
  margin-left: 20px;
}

.btn-wrap {
  display: flex;
  flex-flow: row;
}

.btn-wrap>button {
  margin-left: 10px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  flex-grow: 1;
}

.btn-wrap>button:first-of-type {
  margin-left: 0;
}
</style>
